<template>
	<div>
		<PageHeader />
		<VideoPlayer />

		<Form />
		<Footer />
	</div>
</template>

<script>
import PageHeader from './components/Header.vue';
import VideoPlayer from './components/VideoPlayer.vue';
import Form from './components/FormAlt.vue';
import Footer from './components/Footer.vue';

export default {
	name: 'App',
	components: {
		PageHeader,
		VideoPlayer,
		Form,
		Footer,
	},
};
</script>

<style lang="scss">
@import 'scss/_fonts.scss';
@import 'scss/_variables.scss';
@import 'scss/_grid.scss';

// --------------------------------------------------------------------
// Reset
*,
*::before,
*::after {
	// Standardise sizing
	box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
	padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
// img {
// 	max-width: 100%;
// 	display: block;
// }

/* Natural flow and rhythm in articles by default */
// article > * + * {
// 	margin-top: 1em;
// }

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

button {
	-webkit-tap-highlight-color: transparent;
}
// --------------------------------------------------------------------

body {
	font-family: var(--primary-font), Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--page-bg);
	color: var(--green-dark);
	font-size: 16px;
	line-height: 1.2;
	font-weight: normal;
	letter-spacing: 0.05em;
}

.col {
	&-primary {
		padding: 0 var(--page-padding);
		width: 60vw;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
