<template>
	<div class="component-form grid">
		<section class="form">
			<div class="title">
				HERE’S TO NOT TAKING SUMMERS FOR GRANTED—AND FOR EXCITING THINGS TO COME TO THE ROOFTOP IN THE
				SEASON AHEAD.
			</div>
			<div class="sub-title">
				SIGN UP BELOW TO BE ONE OF THE FIRST TO GET THE SCOOP THIS WINTER.
			</div>
			<form
				action="https://pier17ny.us5.list-manage.com/subscribe/post?u=a2adab824609b2ba32a79b94d&amp;id=a51d272f5c"
				method="post"
				id="mc-embedded-subscribe-form"
				name="mc-embedded-subscribe-form"
				class="form-subscribe"
				novalidate
			>
				<div :class="{ invalid: formInvalid }">
					<!-- <input type="email" v-model.trim="form.email" placeholder="Enter your email address…" /> -->
					<input
						type="email"
						v-model.trim="form.email"
						name="EMAIL"
						class="required email"
						id="mce-EMAIL"
						placeholder="Enter your email address…"
						ref="emailInput"
						target="_blank"
					/>
					<input type="hidden" name="GREENS" value="True" />
					<input type="hidden" name="SEAPORT" value="True" />
					<input type="hidden" name="PIER17" value="True" />
				</div>

				<!-- <div class="optin">
					<input
						type="checkbox"
						v-model="form.optin"
						id="SEAPORT"
						name="SEAPORT"
						class="css-checkbox"
					/>
					<label for="SEAPORT" class="css-label"
						>I’d like to also receive news about other activities within the Seaport District and
						Pier 17.</label
					>
				</div> -->

				<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
				<div style="position: absolute; left: -5000px;" aria-hidden="true">
					<input type="text" name="b_a2adab824609b2ba32a79b94d_a51d272f5c" tabindex="-1" value="" />
				</div>

				<button type="submit" class="btn-subscribe">Subscribe</button>
			</form>

			<div class="caveat">
				Winter dates and more info to be announced at a later date. By subscribing you agree to
				receive communications from Seaport District and Pier 17 businesses.
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Form',
	data() {
		return {
			form: {
				// email: 'test1@defmech.com',
				email: '',
			},
		};
	},
	computed: {
		formInvalid() {
			return false;
		},
		buttonDisabled() {
			return this.form.email === '';
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
	font-family: var(--header-font);
	text-transform: uppercase;
	text-align: center;
	font-size: 24px;
	line-height: 34px;
	max-width: 740px;
	margin: 0 auto;
	margin-bottom: var(--padding-vertical);
}

.sub-title {
	font-family: var(--primary-font-bold);
	text-transform: uppercase;
	text-align: center;
	font-size: 18px;
	letter-spacing: 0.2em;
	line-height: 26px;
	margin-bottom: var(--padding-vertical);
	width: 80%;
	max-width: 440px;
}

.component-form {
	margin-top: var(--padding-vertical);
	margin-bottom: var(--padding-vertical);
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin: 0 auto;

	.error {
		margin-top: var(--padding-vertical);
		color: var(--error-color);
	}

	&-subscribe {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		> * {
			&:not(:last-child) {
				margin-bottom: var(--padding-vertical);
			}
			width: 100%;
		}

		input {
			color: var(--green-dark);
			background-color: var(--page-bg);
			outline: none;

			&::placeholder {
				color: var(--green-dark);
			}
		}

		.invalid {
			position: relative;

			&:before {
				display: block;
				content: 'ERROR';
				font-size: 12px;
				color: var(--error-color);
				position: absolute;
				left: 0;
				top: -18px;
				z-index: 1;
			}

			input[type='email'] {
				border: 1px solid var(--error-color);
				color: var(--error-color);
			}
		}

		input[type='email'] {
			border: 1px solid var(--green-dark);
			padding: 10px 20px;
			width: 100%;
		}

		button[type='submit'] {
			width: 218px;
			height: 55px;
			background-color: var(--green-dark);
			color: var(--color-text-light);
			text-transform: uppercase;
			outline: none;
			border: none;

			font-family: var(--font-primary);

			padding: 17px 20px;

			margin-left: auto;
			margin-right: auto;

			user-select: none;

			&:hover {
				color: #193e25;
				background: #ffa99e;
				cursor: pointer;
			}
		}
	}

	.caveat {
		margin-top: 102px;
		margin-bottom: 100px;
		max-width: 540px;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
	}
}

// --------------------------------------------------------------------
//  Custom checkbox

input[type='checkbox'].css-checkbox {
	position: absolute;
	z-index: -1000;
	left: -1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

input[type='checkbox'].css-checkbox + label.css-label {
	// Space to right of checkbox
	padding-left: 41px;
	height: 26px;
	// Extra margin to counteract the label having a set height.
	margin-bottom: 14px;
	max-width: 600px;
	display: inline-block;
	line-height: 24px;
	background-repeat: no-repeat;
	background-position: 0 0;
	font-size: 16px;
	vertical-align: middle;
	cursor: pointer;
}

input[type='checkbox'].css-checkbox:checked + label.css-label {
	background-position: 0 -26px;
}
label.css-label {
	background-image: url(../assets/csscheckbox_1a1f777b5aff09b018467a6ba9da6c48.png);
	user-select: none;
}

// --------------------------------------------------------------------

@import '../scss/_variables.scss';

@media only screen and (max-width: $grid-mobile-breakpoint) {
	.component-form {
		.title {
			font-size: 16px;
			line-height: 24px;
		}

		.sub-title {
			font-size: 13px;
			line-height: 22px;
		}
	}

	input[type='checkbox'].css-checkbox + label.css-label {
		// Extra margin to counteract the label having a set height.
		margin-bottom: 34px;
		font-size: 14px;
		line-height: 24px;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.error {
			color: var(--error-color);
		}

		&-subscribe {
			button[type='submit'] {
				width: 70%;
			}
		}
	}
}
</style>
