// IE11
import cssVars from 'css-vars-ponyfill';
cssVars({
	// Options...
});

import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
	render: h => h(App),
}).$mount('#app');
