<template>
	<div class="video-player grid-0">
		<div ref="videoEl"></div>
	</div>
</template>

<script>
import Player from '@vimeo/player';

export default {
	name: 'VideoPlayer',
	data() {
		return {
			player: null,
			videoUrl: 'https://vimeo.com/467785804',
		};
	},
	mounted() {
		console.log(`mounted`);

		this.player = this.createPlayer();
	},
	computed: {
		videoSize() {
			return {
				width: window.innerWidth,
				height: window.innerWidth * 0.5625,
			};
		},
	},
	methods: {
		iOS() {
			return (
				['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
					navigator.platform
				) ||
				// iPad on iOS 13 detection
				(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
			);
		},
		createPlayer() {
			const { width, height } = this.videoSize;

			const videoConfig = {
				width,
				height,
				controls: false,
				responsive: true,
				transparent: true,
				autoplay: true,
				loop: true,
			};

			if (this.videoUrl) {
				videoConfig.url = this.videoUrl;
			} else {
				videoConfig.id = this.videoId;
			}

			console.log({ videoConfig });

			const { videoEl } = this.$refs;

			const player = new Player(videoEl, videoConfig);

			if (this.iOS) {
				// mute by default on ios
				console.warn(`ios - muting`);
				player.setVolume(0);
			}

			player.ready().then(
				() => {
					this.loaded = true;
				},
				err => {
					this.onVideoError(err);
				}
			);

			// this.addListeners(player);

			return player;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-player {
	display: block;
	background-color: var(--green-dark);
}
</style>
