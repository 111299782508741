<template>
	<div id="hh-footer-wrapper">
		<div id="hh-footer">
			<div class="row">
				<h3>
					<a target="_blank" class="green" href="https://www.seaportdistrict.nyc/our-partners/"
						>FOUNDING PARTNERS</a
					>
				</h3>
				<ul class="partner-logos">
					<li><img src="images/heineken-blacker.svg" alt="Heineken" /></li>
					<li><img src="images/chase-black.svg" alt="Partner Chase" /></li>
					<li><img src="images/lincoln-black.svg" alt="Lincoln" /></li>
					<li><img src="images/pepsi-black.svg" alt="Pepsi" /></li>
				</ul>
			</div>

			<div class="row flex-wrap border-top max-width-content">
				<div class="col-desk-3 col-mob-4 full-width-old-tablet howardhughes">
					<a href="https://www.howardhughes.com/" target="_blank"
						><img width="194" src="images/logo-howardhughes_blk.png" alt="Howard Hughes"
					/></a>
				</div>

				<div class="col-desk-6 col-mob-4 full-width-old-tablet footer__copyright">
					<p>The Rooftop at Pier 17, 89 South Street, New York, NY 10038</p>
					<p>Copyright &copy; 2020 The Howard Hughes Corporation. <br />All rights reserved.</p>
					<span
						><a target="_blank" href="https://www.pier17ny.com/privacy-policy/"
							>Privacy policy</a
						></span
					>
					<span
						><a target="_blank" href="https://www.pier17ny.com/terms-of-service/"
							>Terms of Service</a
						></span
					>
				</div>

				<div class="col-desk-3 col-mob-4 full-width-old-tablet social">
					<ul>
						<li>
							<a target="_blank" href="https://www.instagram.com/pier17ny/" class="instagram"
								>Instagram</a
							>
						</li>
						<li>
							<a target="_blank" href="https://www.facebook.com/Pier17NY/" class="facebook"
								>Facebook</a
							>
						</li>
						<li>
							<a target="_blank" href="https://www.twitter.com/pier17ny/" class="twitter"
								>Twitter</a
							>
						</li>
					</ul>
				</div>
			</div>

			<a id="pier17Btn" href="https://www.pier17ny.com/" target="_blank" class="pier17"></a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			form: {
				email: '',
				optin: false,
			},
		};
	},
	methods: {
		onSubmit() {
			console.log(`onSumbit`);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$green: #013f23;

#hh-footer-wrapper {
	width: 100%;
	background: white;
}

#hh-footer {
	font-size: 14px;
	line-height: 36px;
	text-align: center;
	margin: 0 auto;
	padding: 40px;
	color: rgb(18, 18, 18);
	background: white;

	max-width: 1000px;

	.instagram,
	.facebook,
	.twitter {
		background: url('../assets/images/facebook.svg') no-repeat;
		width: 24px;
		height: 24px;
		background-size: contain;
		text-indent: -9999px;
		display: block;
		margin-left: 15px;
	}

	.instagram {
		background: url('../assets/images/instagram.svg') no-repeat;
		background-size: contain;
		width: 24px;
		height: 24px;
		margin-left: 0;
	}

	.twitter {
		background: url('../assets/images/twitter.svg') no-repeat;
		background-size: contain;
		width: 24px;
		height: 24px;
	}

	h3 a {
		margin-bottom: 40px;
		color: var(--green-dark);
		display: block;
	}

	.pier17 {
		width: 136px;
		height: 126px;
		position: fixed;
		right: 0;
		top: calc(100vh - 126px);
		z-index: 9;

		&:before,
		&:after {
			background-size: contain;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transition: opacity 0.3s;
		}

		&:before {
			z-index: -101;
			background-image: url('../assets/images/pier17.svg');
		}

		&:after {
			z-index: -100;
			opacity: 0;
			background-image: url('../assets/images/pier17hover.svg');
		}

		&:hover {
			&:after {
				opacity: 1;
			}

			&:before {
				opacity: 0;
			}
		}

		&.white {
			&:before {
				z-index: -101;
				background-image: url('../assets/images/pier17white.svg');
			}

			&:after {
				z-index: -100;
				background-image: url('../assets/images/pier17hover.svg');
			}
		}

		&.hidden {
			display: none;
		}
	}

	.partner-logos,
	ul {
		list-style: none;
		display: flex;
		margin: 0 auto;
		max-width: 900px;
		justify-content: space-around;
		align-items: center;
		margin-bottom: 30px;
		padding: 0;

		li img {
			max-width: 130px;
			max-height: 60px;
			width: auto;
			height: auto;
		}
	}

	.border-top {
		border-top: 1px solid #ededed;
		padding-top: 30px;
		margin-top: 40px;
	}

	.one-fifth {
		align-items: center;
		display: flex;
		width: 20%;

		ul {
			margin-bottom: 0;
		}
	}

	.howardhughes {
		margin-top: 15px;
		text-align: left;
	}

	.social {
		align-items: center;
		display: flex;

		ul {
			margin: 0;
			margin-left: auto;
			justify-content: flex-end;

			li {
				transition: all 0.3s ease;
				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	.footer__copyright {
		p {
			padding-bottom: 0;
			margin-bottom: 0;
			line-height: 22px;
		}

		a {
			margin-right: 20px;
			color: black;
			text-decoration: none;
			position: relative;
			transition: all 0.3s ease;

			&:hover {
				opacity: 0.5;
			}
		}

		a:after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #121212;
		}
	}

	@media only screen and (max-width: 1000px) {
		.partner-logos {
			flex-wrap: wrap;
			justify-content: center;
			li {
				width: 50%;
				margin-bottom: 40px;
				padding: 10px;
			}
		}

		.full-width-old-tablet {
			width: 100%;
		}

		.howardhughes {
			margin-top: 0px;
			padding-top: 40px;
			text-align: center;
		}

		.social ul {
			margin: 0 auto;
		}

		.instagram {
			margin-left: -15px;
		}

		.one-fifth,
		.three-fifths {
			width: 100%;
			justify-content: center;
		}

		.footer__copyright {
			margin-bottom: 30px;
			font-size: 14px;

			p,
			a {
				font-size: 14px;
			}

			p {
				margin-bottom: 20px;
			}

			span {
				display: block;
				width: auto;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		h3 {
			font-size: 18px;
		}

		.border-top {
			margin-top: 0;
		}

		.partner-logos {
			li {
				img {
					padding: 0 10px;
				}
			}
		}
	}

	@media only screen and (max-width: 320px) {
		.partner-logos {
			li {
				img {
					max-width: 110px;
					max-height: 50px;
				}
			}
		}
	}
}
</style>
